.dl-menuwrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective-origin: 50% 200%;
  -moz-perspective-origin: 50% 200%;
  perspective-origin: 50% 200%;
  @media #{$notsmall} {
    position: fixed;
    max-width: 300px;
    top: 25px;
    left: 25px;
  }
  #navbutton {
    display: block;
    top: 0;
    left: 0;
    background: $comp-color;
    border: none;
    width: 48px;
    height: 45px;
    text-indent: -900em;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    outline: none;
    @include border-radius(0, 3px, 0, 0);
    opacity: 0.6;
    @media #{$notsmall} {
      @include border-radius(3px, 3px, 3px, 3px);
    }
  }
  #navbutton:hover,
  #navbutton.dl-active,
  ul {
    background: #aaa;
  }
  #navbutton:after {
    content: "";
    position: absolute;
    width: 68%;
    height: 5px;
    background: $white;
    top: 10px;
    left: 16%;
    box-shadow: 0 10px 0 $white, 0 20px 0 $white;
  }
  #navbutton.dl-active {
    display: none;
  }

  ul {
    padding: 0;
    list-style: none;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  li {
    position: relative;
    h4 {
      margin: 0;
      padding: 15px 20px 0;
      color: rgba($white, 0.9);
    }
    p {
      margin: 0;
      padding: 15px 20px;
      @include font-rem(14);
      color: rgba($white, 0.8);
      font-weight: 300;
      a {
        display: inline;
        padding: 0;
        @include font-rem(14);
      }
    }
    i {
      // display: inline-block;
    }
    a {
      display: block;
      position: relative;
      padding: 10px 20px;
      @include font-rem(14);
      line-height: 20px;
      font-weight: 400;
      color: $white;
      outline: none;
      text-decoration: none;
    }
    &.dl-back > a {
      padding-left: 30px;
      background: rgba(0, 0, 0, 0.2);
    }
    &.dl-back:after,
    > a:not(:only-child):after {
      position: absolute;
      top: 0;
      line-height: 40px;
      font-family: "fontawesome";
      color: $white;
      speak: none;
      -webkit-font-smoothing: antialiased;
      content: "\f105";
    }
    &.dl-back:after {
      left: 10px;
      color: rgba(212, 204, 198, 0.5);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    > a:after {
      right: 10px;
      color: rgba(0, 0, 0, 0.15);
    }
  }
  .dl-menu {
    margin: 0;
    position: absolute;
    width: 100%;
    max-width: 400px;
    overflow-y: auto;
    max-height: 600px;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0 12px 24px rgba($black, 0.4);
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    @media #{$notsmall} {
      @include border-radius(3px, 3px, 3px, 3px);
      max-height: 650px;
    }
  }
  .dl-menu.dl-menu-toggle {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .dl-menu.dl-menuopen {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
  }
  .dl-submenu {
    @include rounded(3px);
    box-shadow: 0 12px 24px rgba($black, 0.4);
    .btn {
      margin-bottom: 0;
    }
  }
  /* Hide the inner submenus */
  li .dl-submenu {
    display: none;
  }
}
/* 
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link. 
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
  display: none;
}
.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
  display: block;
}
/* Animation classes for moving out and in */
.dl-menu.dl-animate-out {
  -webkit-animation: MenuAnimOut 0.4s ease;
  -moz-animation: MenuAnimOut 0.4s ease;
  animation: MenuAnimOut 0.4s ease;
}
@-webkit-keyframes MenuAnimOut {
  100% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
}
@-moz-keyframes MenuAnimOut {
  100% {
    -moz-transform: translateZ(300px);
    opacity: 0;
  }
}
@keyframes MenuAnimOut {
  100% {
    transform: translateZ(300px);
    opacity: 0;
  }
}
.dl-menu.dl-animate-in {
  -webkit-animation: MenuAnimIn 0.4s ease;
  -moz-animation: MenuAnimIn 0.4s ease;
  animation: MenuAnimIn 0.4s ease;
}
@-webkit-keyframes MenuAnimIn {
  0% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}
@-moz-keyframes MenuAnimIn {
  0% {
    -moz-transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    -moz-transform: translateZ(0px);
    opacity: 1;
  }
}
@keyframes MenuAnimIn {
  0% {
    transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0px);
    opacity: 1;
  }
}
.dl-menuwrapper > .dl-submenu.dl-animate-in {
  -webkit-animation: SubMenuAnimIn 0.4s ease;
  -moz-animation: SubMenuAnimIn 0.4s ease;
  animation: SubMenuAnimIn 0.4s ease;
}
@-webkit-keyframes SubMenuAnimIn {
  0% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}
@-moz-keyframes SubMenuAnimIn {
  0% {
    -moz-transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    -moz-transform: translateZ(0px);
    opacity: 1;
  }
}
@keyframes SubMenuAnimIn {
  0% {
    transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0px);
    opacity: 1;
  }
}
.dl-menuwrapper > .dl-submenu.dl-animate-out {
  -webkit-animation: SubMenuAnimOut 0.4s ease;
  -moz-animation: SubMenuAnimOut 0.4s ease;
  animation: SubMenuAnimOut 0.4s ease;
}
@-webkit-keyframes SubMenuAnimOut {
  0% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
}
@-moz-keyframes SubMenuAnimOut {
  0% {
    -moz-transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    -moz-transform: translateZ(-300px);
    opacity: 0;
  }
}
@keyframes SubMenuAnimOut {
  0% {
    transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    transform: translateZ(-300px);
    opacity: 0;
  }
}
/* No Touch Fallback */
.no-touchevents .dl-menuwrapper li a:hover {
  background: rgba(255, 248, 213, 0.1);
}
/* No JS Fallback */
.no-js {
  .dl-trigger {
    display: none;
  }
  .dl-menuwrapper {
    position: initial;
    @media #{$notsmall} {
      position: absolute;
    }
  }
  .dl-menuwrapper .dl-menu {
    position: relative;
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }
  .dl-menuwrapper li .dl-submenu {
    display: block;
  }
  .dl-menuwrapper li.dl-back {
    display: none;
  }
  .dl-menuwrapper li > a:not(:only-child) {
    background: rgba(0, 0, 0, 0.1);
  }
  .dl-menuwrapper li > a:not(:only-child):after {
    content: "";
  }
  .dl-menu {
    max-height: 100%;
  }
  .dl-menu li {
    display: block;
  }
}
// Menu Color
.dl-menuwrapper button:hover,
.dl-menuwrapper button.dl-active,
.dl-menuwrapper ul {
  background: $comp-color;
}
// Fix for IE
.dl-menu li {
  display: none;
}
.dl-menuopen li {
  display: block;
}

.sidenav {
  height: 100%;
  width: 12.5%;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.85;
  overflow-x: hidden;
  padding-top: 10px;
  font-family: "fontawesome";
  // 	&:hover {
  // 	right:0;
  // }
}

.sidenav h1 {
  padding: 1px 4px 1px 4px;
  text-decoration: none;
  font-size: 16px;
  color: whitesmoke;
  display: block;
  z-index: 1000;

  // &:hover {
  // 	color:red !important;
  // 	text-decoration: none;
  // }
}

.main {
  margin-left: 160px; /* Same as the width of the sidenav */
  font-size: 22px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

@media screen and (max-width: 1024px) {
  .sidenav {
    display: None;
  }
  .sidenav h1 {
    display: None;
  }
}
@media screen and (min-width: 1340px) {
  .sidenav {
    width: 18%;
  }
}

.footer {
  position: fixed;
  right: 16px;
  bottom: 0;
  height: auto;
  width: auto;
  background-color: black;
  opacity: 0.85;
  font-family: sans-serif;
  font-weight: 200;
  color: white;
  text-align: center;
}

#overlaybutton {
  top: 10px;
  left: 0;
  display: block;
  float: left;
  background: whitesmoke;
  color: black;
  border: none;
  width: 120px;
  height: 40px;
  overflow: auto;
  position: relative;
  cursor: pointer;
  outline: none;
  @include border-radius(0, 3px, 0, 0);
  opacity: 0.6;
  @media #{$notsmall} {
    @include border-radius(3px, 3px, 3px, 3px);
  }
}
#overlaybutton:hover {
  background: #aaa;
}

